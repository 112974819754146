
.footer {
    /* background-color: #232323; */
    background-color: #ffd6a0b2;
    color: #884A39;
    padding: 8px;
    text-align: center;
  }
  
  .contact-info {
    margin-top: 16px;
  }

  .contact-info a {
    color: #007BFF; /* Change the color to a visually distinct one */
    text-decoration: underline; /* Underline the links */
    cursor: pointer; /* Add a pointer cursor to indicate interactivity */
  }
  
  .contact-info a:hover {
    color: #0056b3; /* Change the color on hover for a visual effect */
  }