/* MenuInfo.css */

.menu-info-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
 }

 .menu-info-date{
    font-size: 24px;
    text-align: center;
    color: #884A39;
    margin: 24px;    
 }

.menu-info-text{
    font-size: 16px;
    text-align: center;
    color: #616161;
    margin: 8px;
}