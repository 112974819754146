.App {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  
}

/* Add styles to push the footer to the bottom */
.main-content {
  flex: 1;
  padding-bottom: 80px;
}