.Header {
    position:relative;
    width: 100%;
    height: 30vh; 
    background-image: url('../../../public/images/header_background_dimed.jpg');
    background-size: cover;
    background-position: center;
    color: white;
    background-color: rgba(255, 255, 255, 0.481);
  }
  
  .header-content {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    /* background-color: rgba(255, 255, 255, 0.481); */
  }

  .header-text {
    text-align: center;    
    color: #884A39;
    font-family: 'Amatic SC', cursive;
    font-weight: 580;
    font-size: 80px;
    margin:20px 20px;
  }