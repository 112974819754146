.days-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    width: 100%;
    padding: 10px 0;
  }

  .day {
    /* background-color: #232323; */
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: 24px;
    color: #884A39;
    font-weight: bold;
    font-family: 'Amatic SC', cursive;
    text-decoration: none;
    padding: 5px 10px;
    margin: 0;
    border-radius: 10px;
  }

  /* .day:focus {
    text-decoration: underline;
  } */

  .day.selected {
    background-color: #884A39;
    color: #ffffff;
}