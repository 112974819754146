.food-offer-container {  
  padding-left: 16px;
  padding-right: 16px;  
}

.title {
  /* color:#ffcc4d; */
  color:#C38154;
  font-size: 24px;
  text-align: center;
  margin: 10px;
  font-weight: bold;
  font-family: 'Amatic SC', cursive;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5); */
  /* text-shadow:
    -1px -1px 0 #515151,
    1px -1px 0 #515151,
    -1px 1px 0 #515151,
    1px 1px 0 #515151;     */
}

.food-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  color: #353535;
  width: 100%; 
  padding: 4px 0;
  border-bottom: 2px solid #f0f0f0;
}

.food-name{
  font-size: 16px;
  text-align: left;
}

.food-price { 
  display: flex;
  justify-content: center;
  align-items: center; 
  color: #616161;
  font-weight: bold;
  padding-left: 16px;  
}