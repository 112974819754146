.about-container {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    max-width: 600px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
 }

 .about-header {
    font-size: 24px;
    text-align: center;
    color: #884A39;
    margin: 24px; 
 }

 .about-content{
    font-size: 16px;
    text-align: center;
    color: #616161;
    margin: 8px;
 }