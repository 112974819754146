  .navigation-bar {
    /* position: fixed; */
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    background-color: #00000000;
    z-index: 1000;
  }
  
  .nav-options {
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    padding: 10px 20px;
  }
  
  .nav-options div {
    margin-right: 20px;
  }
  
  .nav-options a {
    text-decoration: none;
    font-size: 32px;
    font-family: 'Amatic SC', cursive;
    color: #884A39;
    font-weight: bold;
    position: relative;
  }

  .nav-options a:hover {
    text-decoration: underline;
  }
  
  .nav-options a.selected {
    text-decoration: underline;
  }
